import { parseColor } from '@react-stately/color'
import {
	json,
	type LoaderFunctionArgs,
	type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	type ShouldRevalidateFunction,
	useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { css } from '#styled-system/css/css.js'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import pandaStylesheetUrl from './styles/panda.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { useOptionalUser } from './utils/user.ts'

export const links: LinksFunction = () => {
	return [
		{
			rel: 'preload',
			href: '/fonts/2aaf0723e720e8b9.woff2',
			as: 'font',
			type: 'font/woff2',
			crossOrigin: 'anonymous',
		} as const,
		{
			rel: 'preload',
			href: pandaStylesheetUrl,
			as: 'style',
		},
		{
			rel: 'stylesheet',
			href: pandaStylesheetUrl,
		},
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.png' },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [{ title: data ? 'Notion Icons' : 'Error | Notion Icons' }]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							email: true,
							name: true,
							username: true,
							iconStrokeWidth: true,
							iconColor: true,
							colorScheme: true,
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
	currentUrl,
	currentParams,
	nextUrl,
	nextParams,
	defaultShouldRevalidate,
}) => {
	if (
		currentUrl.pathname.includes('/icon-set/') &&
		nextUrl.pathname.includes('/icon-set/') &&
		currentParams.iconSetSlug === nextParams.iconSetSlug
	) {
		return false
	}

	return defaultShouldRevalidate
}

function Document({
	children,
	nonce,
	user,
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	user?: ReturnType<typeof useOptionalUser>
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	return (
		<html lang="en" className={user?.colorScheme}>
			<head>
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body
				className={css({
					backgroundColor: 'gray2',
					color: 'gray11',
				})}
				style={
					{
						fontFamily: '__Inter_9c9965, __Inter_Fallback_9c9965',
						fontStyle: 'normal',
						'--icon-stroke-width': user ? user.iconStrokeWidth : 1.5,
						'--icon-color': user
							? parseColor(user.iconColor).toString('hex')
							: '#674DC3FF',
					} as React.CSSProperties
				}
			>
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const user = useOptionalUser()
	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'

	return (
		<Document
			nonce={nonce}
			allowIndexing={allowIndexing}
			user={user}
			env={data.ENV}
		>
			<Outlet />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
